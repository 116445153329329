import { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import s from './s.module.css'
import { useCustomQueryWithParams } from '@/shared/hooks/useCustomQueryWithParams.ts'
import { GET_PROFILE } from '@/auth/api/constants.ts'
import { authApi } from '@/auth/api'
import Header from '@/shared/components/Header'
import { IUser, usePlayers } from '@/shared/contexts/PlayersContext.tsx'
import ChangingText from '@/shared/components/ChangingText'

function MenuLayout() {
    const { data: userProfile } = useCustomQueryWithParams(GET_PROFILE, authApi.getProfile, {
        retry: 3,
    })
    const { setUser } = usePlayers()
    const [pageLoading, setPageLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setPageLoading(false)
        }, 3000)
    }, [])

    useEffect(() => {
        if (userProfile) {
            setUser((prevUser: IUser) => ({
                ...prevUser,
                backendUserId: userProfile.data.id,
            }))
        }
    }, [setUser, userProfile])

    return pageLoading ? (
        <ChangingText />
    ) : (
        <div className={s.container}>
            <Header balance={userProfile?.data.balance} />
            <div className={s.content}>
                <Suspense fallback="Loading...">
                    <Outlet />
                </Suspense>
            </div>
        </div>
    )
}

export default MenuLayout
