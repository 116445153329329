import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react'
import { io, Socket } from 'socket.io-client'
import { MatchRoomEvent } from '@/shared/types/MatchRoomEvent.ts'
import { WaitingRoomEvent } from '@/shared/types/WaitingRoomEvent.ts'
import { EmmitParam } from '@/shared/types/EmmitType.ts'

const SocketContext = createContext<{
    emit: (emitString: string, emitParam?: any) => void
    matchRoomData: MatchRoomEvent | undefined
    waitingRoomData: WaitingRoomEvent | undefined
    setMatchRoomHandler: (handler: (data: MatchRoomEvent) => void) => void
    closeSocket: () => void
    openSocket: () => void
} | null>(null)

export function SocketProvider({ children }: { children: React.ReactNode }) {
    const [socket, setSocket] = useState<Socket | null>(null)
    const [matchRoomData, setMatchRoomData] = useState<MatchRoomEvent | undefined>(undefined)
    const [waitingRoomData, setWaitingRoomData] = useState<WaitingRoomEvent | undefined>(undefined)
    const matchRoomHandlerRef = useRef<((data: MatchRoomEvent) => void) | null>(null)

    const initializeSocket = useCallback(() => {
        const newSocket = io(import.meta.env.VITE_PUBLIC_DOMAIN, {
            extraHeaders: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
            autoConnect: false,
        })

        setSocket(newSocket)
        return newSocket
    }, [])

    useEffect(() => {
        const newSocket = initializeSocket()
        newSocket.connect()

        return () => {
            newSocket.close()
        }
    }, [initializeSocket])

    const closeSocket = useCallback(() => {
        if (socket) {
            console.log('Socket closed manually')
            socket.close()
        }
    }, [socket])

    const openSocket = () => {
        if (socket) {
            console.log('Socket opened manually')
            socket.open()
        } else {
            const newSocket = initializeSocket()
            newSocket.connect()
        }
    }

    const emit = (emitString: string, emitParam?: EmmitParam) => {
        if (!socket) {
            return console.error('No socket to emit')
        }

        if (socket.connected) {
            socket.emit(emitString, emitParam)
        } else {
            openSocket()
            socket.once('connect', () => {
                console.log('Socket connected, emitting event')
                socket.emit(emitString, emitParam)
            })
        }
    }

    useEffect(() => {
        if (!socket) {
            return
        }

        const handleWaitingRoom = (data: WaitingRoomEvent) => {
            setWaitingRoomData(data)
        }

        const handleMatchRoom = (data: MatchRoomEvent) => {
            if (data.type === 'end' || data.type === 'canceled') {
                closeSocket()
            }
            if (matchRoomHandlerRef.current) {
                matchRoomHandlerRef.current(data)
            } else {
                setMatchRoomData(data)
            }
        }

        socket.on('waitingRoom', handleWaitingRoom)
        socket.on('matchRoom', handleMatchRoom)

        return () => {
            socket.off('waitingRoom', handleWaitingRoom)
            socket.off('matchRoom', handleMatchRoom)
        }
    }, [socket, closeSocket])

    const setMatchRoomHandler = (handler: (data: MatchRoomEvent) => void) => {
        matchRoomHandlerRef.current = handler
    }

    return (
        <SocketContext.Provider
            value={{ waitingRoomData, matchRoomData, emit, setMatchRoomHandler, closeSocket, openSocket }}
        >
            {children}
        </SocketContext.Provider>
    )
}

export const useGameEvents = () => {
    const context = useContext(SocketContext)
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider')
    }
    return context
}
