import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import s from './GameSetupLayout.module.css'

function GameSetupLayout() {
    return (
        <div className={s.container}>
            <div className={s.content}>
                <Suspense fallback="Loading...">
                    <Outlet />
                </Suspense>
            </div>
        </div>
    )
}

export default GameSetupLayout
