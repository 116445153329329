import { createContext, useContext, useState } from 'react'
import { WebAppUser } from '@twa-dev/types'

export interface IUser extends WebAppUser {
    backendUserId: string
    balance: number
}

const PlayersContext = createContext<{
    user: IUser | null
    opponent: any
    setUser: (user: any) => void
    setOpponent: (opponent: any) => void
    setMatchId: (matchId: string) => void
    matchId: string
} | null>(null)

export function PlayersProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<IUser | null>(null)
    const [opponent, setOpponent] = useState<string>('')
    const [matchId, setMatchId] = useState<string>('')

    return (
        <PlayersContext.Provider value={{ user, opponent, setUser, setOpponent, setMatchId, matchId }}>
            {children}
        </PlayersContext.Provider>
    )
}

export const usePlayers = () => {
    const context = useContext(PlayersContext)
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider')
    }
    return context
}
