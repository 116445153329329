import './instrument.js'
import './reset.css'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import { lazy, Suspense, useEffect, useState } from 'react'
import { WebAppInitData } from '@twa-dev/types'
import WebApp from '@twa-dev/sdk'
import GameLayout from './layouts/Game'
import GameSetupLayout from '@/layouts/GameSetup'
import { routes } from '@/shared/constants/routes.ts'
import { loginRequest } from '@/auth/api/login.ts'
import { useCustomQueryWithParams } from '@/shared/hooks/useCustomQueryWithParams.ts'
import { GET_PROFILE } from '@/auth/api/constants.ts'
import { authApi } from '@/auth/api'
import { IUser, usePlayers } from '@/shared/contexts/PlayersContext.tsx'
import MenuLayout from '@/layouts/Menu'
import { shouldLogin } from '@/utils/auth/login.ts'

const GameLazy = lazy(() => import('@/pages/Game'))
const GameSetupLazy = lazy(() => import('@/pages/GameSetup'))
const MenuLazy = lazy(() => import('@/pages/Menu'))

function App() {
    const [initData, setInitData] = useState<WebAppInitData>()
    const { setUser } = usePlayers()
    const accessToken = localStorage.getItem('accessToken')

    const { data: userProfile } = useCustomQueryWithParams(GET_PROFILE, authApi.getProfile, {
        enabled: !!accessToken,
        retry: 3,
    })

    useEffect(() => {
        const webAppInitData: WebAppInitData = WebApp.initDataUnsafe
        setInitData(webAppInitData)

        setUser((prevUser: IUser) => ({
            ...prevUser,
            ...webAppInitData.user,
        }))

        console.log(webAppInitData)
    }, [setUser])

    useEffect(() => {
        if (!initData) {
            return
        }
        if (shouldLogin(initData, accessToken)) {
            loginRequest(initData)
                .then((res: { accessToken: string }) => {
                    localStorage.setItem('accessToken', res.accessToken)
                })
                .catch(() => console.error('Something went wrong while login request'))
        }
    }, [initData, accessToken, userProfile])

    useEffect(() => {
        const tg = window.Telegram.WebApp
        tg.expand()
    }, [])

    return (
        <>
            <Routes>
                <Route path={routes.menu} element={<MenuLayout />}>
                    <Route
                        index
                        element={
                            <Suspense fallback="Loading">
                                <MenuLazy />
                            </Suspense>
                        }
                    />
                </Route>
                <Route path={routes.game.setup} element={<GameSetupLayout />}>
                    <Route
                        index
                        element={
                            <Suspense fallback="Loading">
                                <GameSetupLazy />
                            </Suspense>
                        }
                    />
                </Route>
                <Route path={routes.game.base} element={<GameLayout />}>
                    <Route
                        index
                        element={
                            <Suspense fallback="Loading">
                                <GameLazy />
                            </Suspense>
                        }
                    />
                </Route>
            </Routes>
        </>
    )
}

export default App
